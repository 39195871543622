import {
  TableRow,
  TableCell
} from "@mui/material";
import styled, { css } from "styled-components";
import {
  grey,
  blue,
  blueGrey
} from "@mui/material/colors";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Flex } from "style";
import {
  transparentize
} from "polished";

export const TableDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  position: relative;
`

export const TableContainer = styled.div`
  display: block;
  flex: 2;
  width: 100%;
  min-height: calc(100% - 120px);
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
  position: relative;
`

export const THeadRow = styled(TableRow)`
  &.MuiTableRow-root {
    padding: 5px 0;
    //background-color: blue;
    color: #fff;  // Text color
  }`

export const TBodyRow = styled(TableRow) <{ selected?: boolean, depth?: number }>`

  &.MuiTableRow-root {
    position: relative;
    background-color: #fff;
    ${props => props.selected && css`
       background: ${transparentize(0.4, blue["50"])}!important;
    `}

    ${props => props.selected && props.depth && css`
      background-color: transparent !important;
    `}


    ${({ depth, selected }) => depth && css`
      
      & + tr[depth="0"] {
        ${TDCellContainer} {
          box-shadow: rgb(38 50 56 / 25%) 0px 1.2px 0px 0px inset;
          
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            border-top: 1px solid ${transparentize(0.95, blueGrey["900"])};
            box-shadow: none;
          }
          
          
        }
      }
      
      ${TDCellContainer} {
        border-top: 1px solid ${transparentize(0.95, blueGrey["900"])};
        border-bottom: 1px solid ${transparentize(0.95, blueGrey["900"])};
        background-color: #fff !important;
        ${selected && css`
            background: ${transparentize(0.4, blue["50"])}!important;
         `};
        
        
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          border: none;
          background-color: white  !important;
          box-shadow: none;
        }

        &:nth-child(4) {
          box-shadow: rgb(38 50 56 / 25%) 1.2px 0px 0px 0px inset;
          border-bottom-left-radius: 0px;
          border-left: 1px solid ${transparentize(0.95, blueGrey["900"])};
        }

        &:last-child {
          border-right: 1px solid ${transparentize(0.95, blueGrey["900"])};
          border-bottom-right-radius: 4px;
        }
      }
    `}
  }
`

export const TDCellContainer = styled(TableCell)`
  &&& {
    height: 30px;
    max-width: 100%;
    line-height: 140%;
    padding: 2px 4px;
    border-right: 0.5px solid ${grey["300"]};
  }
`;

export const TDExpandedContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: 100%;

  > div {
    &:last-child {
      padding-top: 2px;
    }
  }

`
export const TDExpandedIconBox = styled(Flex)`
  padding-right: 4px;
  justify-content: center;
  align-items: center;
`

export const TDExpandIconOpen = styled(ExpandMoreIcon)`

`

export const TDCollapseIconOpen = styled(ExpandLessIcon)`

`

