import styled from "styled-components";
import {
  Box,
  Popover
} from "@mui/material";
import { Flex } from "style";

export const PopoverHoverBox = styled(Flex)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; 
`

export const PopoverHoverChildBox = styled(Box)`
  display: flex;
  align-items: center; 
  width: 100%;
  height: 100%;
  justify-content: flex-end; 
`

export const StyledPopover = styled(Popover)`
  display: flex;
  align-items: center; 
  &&& {
    font-size: 12px;
  }
`
