import styled, { css } from "styled-components";
import { Flex } from "style";
import {
  grey,
  blue
} from "@mui/material/colors";
import {
  TableEmptyCell
} from "components/Table/TableEmpty/style";
import ButtonIconComponent from "components/Button/ButtonIcon";
import { Typography } from "@mui/material";
import Flag from "react-world-flags";

export const AmazonProductOfferContainer = styled(Flex)`
  padding: 15px;
  min-width: 400px;
  min-height: 100px;
  flex-direction: column;
  &&&&& ${TableEmptyCell} {
     min-height: 500px;
     height: 500px;
  }
`

export const AmazonProductHeader = styled(Flex)`
  justify-content: space-between;
  align-content: center;
  margin-bottom: 10px;
  width: 100%;
`

export const AmazonProductAsin = styled(Flex)`
  flex-direction: column;
  align-items: center;
`

export const AmazonProductDataTime = styled(Flex)`
  flex-direction: column;
  align-items: center;
`

export const AmazonProductSyncDataButton = styled(ButtonIconComponent)`
  &&& {
    background-color: ${blue['800']};
    color: #fff;
    border: 2px solid ${blue['500']};
    box-shadow: 0 2px 2px #888;
    max-height: 30px;
    max-width: 30px;
    &:hover {
      transform: scale(1.08);
    }
    &:active {
      transform: scale(0.92);
    }
  }
`

export const AmazonProductNumOfWB = styled(Flex)`
  flex-direction: column;
  align-items: center;
`

export const AmazonProductOffersPart = styled(Flex)`
  flex: 2;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 25px;
`
export const AmazonProductOffersUSPart = styled(Flex)`
  flex-direction: column;
`

export const CountryFlags = styled(Flag)`
  margin-left: 10px;
  width: 20px;
  height: 20px;
`

export const AmazonProductOffersCAPart = styled(Flex)`
  flex-direction: column;

`

export const AmazonProductOfferTitle = styled(Typography)`
  &&& {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  }
`

export const AmazonProductFooter = styled(Flex)`
  min-height: 40px;
  padding: 5px 10px;
  justify-content: flex-end;
  align-items: center;
`

export const AmazonOfferLabel = styled(Flex)`
  height: 100%;
  min-width: 100px;
  opacity: 1;
  color: ${grey["600"]};
  margin-right: 10px;
  justify-content: center;
  align-items: center;
`

export const AmazonOfferValue = styled(Flex) <{ align?: string }>`
  justify-content: flex-start;
  align-items: center;
  flex: 2;
  color: #000;
  
  ${props => props?.align && css`
    justify-content: ${props.align};
  `}
`

export const AmazonProductOfferSellerCell = styled(Flex)`
  width: 100%;
  justify-content: center;
  align-content: center;
  gap: 10px;
`

export const AmazonProductOfferSellerCellText = styled.div`
  flex: 2;
  text-align: center;
`

export const AmazonProductOfferIsByBox = styled(Flex)`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
`
export const EmptyCircle = styled(Flex)`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 15px;
  height: 15px;
`

export const CircleGreen = styled(Flex) <{ isOur?: boolean }>`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #1aad1f;
  
  ${props => props.isOur && css`
    background: hsl(210, 78.70%, 56.10%);;
  `}
`
