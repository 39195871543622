import styled from "styled-components";
import { Box } from "@mui/material";

export const TdCellEllipsisContainer = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  display: flex; 
  align-items: center;
`


export const TdCellEllipsisBox = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
