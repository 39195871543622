import { SHEET_TAB_IDS } from "def";
import OptiScore from "pages/Legacy/Marketing/components/OptiScore";
import React from "react";
import AmazonLink from "pages/Legacy/Marketing/components/AmazonLink";
import TdCellEllipsis from "components/Table/TableCells/TdCellEllipsis";
import { formatPrice, formatQuantity } from "Utils";
import { FieldColumnType } from "components/Table/TableHeader/FilterSortCell/d";
import { _getCategoryValueBySheetId } from "helpers";
import { BasePriceHeader, LastDateSoldHeader, SmallHeader, } from "pages/Legacy/Order/365/style";
import OptiscoreHeader from "components/TableHeaders/OptiscoreHeader";
import BasePrice from "pages/Legacy/components/BasePrice";
import Bsr from "pages/Legacy/components/Bsr";
import Order365Category from "pages/Legacy/Order/365/Category";
import RestrictedFields from "pages/RestrictedTableFields";

const CategoryFilter = React.lazy(() => import("pages/Legacy/Order/365/Category/CustomFilter"))
const OptiScoreUsed = React.lazy(() => import("pages/Legacy/Marketing/components/OptiScoreUsed"))

export const requestOptions = {}

// @ts-ignore
export const tableColumns = [

    {
        Header: 'BSR',
        accessor: 'amazonBsr',
        canSort: true,
        canFilter: true,
        align: 'center',
        fieldType: FieldColumnType.number,
        style: {
            minWidth: 75,
            width: 75
        },
        headerStyle: {
            paddingLeft: 10
        },
        Cell: (props: any) => <Bsr row={props.row} />//getAmazonBsr(props.value)
    },
    {
        Header: 'Category',
        accessor: 'category',
        canFilter: true,
        canSort: false,
        fieldType: FieldColumnType.selection,
        align: 'center',
        style: {
            minWidth: 90,
            width: 90,
        },
        Cell: (props: any) => <Order365Category {...props} noUsed={true} />,
        fn: (value: any) => _getCategoryValueBySheetId(value, SHEET_TAB_IDS.SHEET_365_PLUS),
        customFn: true,
        customFilter: CategoryFilter,
        customFilterId: 'sheetIds'
    },
    ...RestrictedFields,
    {
        Header: <OptiscoreHeader code={"USA"} />,
        accessor: 'amazonOptiScore',
        canSort: true,
        canFilter: true,
        fieldType: FieldColumnType.multiselection,
        style: {
            minWidth: 55,
            width: 55,
        },
        Cell: (props: any) => <OptiScore row={props.row} />
    },
    {
        Header: <OptiscoreHeader code={"CAN"} />,
        accessor: 'amazonOptiScoreCA',
        canSort: true,
        canFilter: true,
        fieldType: FieldColumnType.multiselection,
        style: {
            minWidth: 55,
            width: 55
        },
        Cell: (props: any) => <OptiScore isCa row={props.row} />
    },
    {
        Header: 'ASIN',
        accessor: 'asin',
        canFilter: true,
        align: 'center',
        style: {
            minWidth: 110,
            textAlign: 'center'
        },
        canSort: true,
        Cell: (props: any) => <AmazonLink value={props.value} row={props.row} />
    },
    {
        Header: <BasePriceHeader>B.Price</BasePriceHeader>,
        accessor: 'basePrice',
        canSort: true,
        canFilter: true,
        fieldType: FieldColumnType.number,
        align: 'right',
        style: {
            width: 90,
            minWidth: 90,
        },
        Cell: (props: any) => <BasePrice value={props.value} row={props.row} />
    },
    {
        Header: 'Make',
        accessor: 'make',
        canSort: true,
        canFilter: true,
        style: {
            minWidth: 120
        },
        Cell: (props: any) => <TdCellEllipsis {...props} />
    },
    {
        Header: 'Model',
        accessor: 'modelNumber',
        canSort: true,
        canFilter: true,
        style: {
            minWidth: 120
        },
        Cell: (props: any) => <TdCellEllipsis {...props} />
    },
    {
        Header: 'MPN',
        accessor: 'mpn',
        canSort: true,
        canFilter: true,
        style: {
            minWidth: 140,
        },
        Cell: (props: any) => <TdCellEllipsis {...props} />
    },
    {
        Header: 'SKU',
        accessor: 'sku',
        canSort: true,
        canFilter: true,
        style: {
            minWidth: 140,
        },
        Cell: (props: any) => <TdCellEllipsis {...props} />
    },
    {
        Header: 'Last Sold Am. - C',
        accessor: 'lastSoldAmountChannel',
        canSort: false,
        canFilter: false,
        fieldType: FieldColumnType.string,
        align: 'left',
        style: {
            minWidth: 120,
        },
        Cell: (props: any) => <TdCellEllipsis {...props} />
    },
    {
        Header: <LastDateSoldHeader>Last Date Sold</LastDateSoldHeader>,
        accessor: 'lastDateSold',
        canSort: false,
        canFilter: false,
        fieldType: FieldColumnType.onlyDate,
        align: 'center',
        style: {
            minWidth: 100,
        },
        isClearable: true,
        Cell: (props: any) => {
            if (!props?.value) return ''
            const date = props.value.split('-')
            return `${date[1]}/${date[2]}/${date[0].substring(2, 4)}`
        }
    },
    {
        Header: <SmallHeader>QTY</SmallHeader>,
        accessor: 'ytdQuantity',
        canSort: true,
        canFilter: true,
        fieldType: FieldColumnType.number,
        align: 'right',
        style: {
            width: 60,
            minWidth: 60,
        },
        Cell: (props: any) => formatQuantity(props.value)
    },
    {
        Header: 'TOTAL SALES',
        accessor: 'ytdTotalSales',
        canSort: true,
        canFilter: true,
        fieldType: FieldColumnType.number,
        align: 'right',
        style: {
            minWidth: 110
        },
        Cell: (props: any) => formatPrice(props.value)
    },
]

export const fnFilters = {
    category: (value: string) => {
        let obj = {} as any
        if (value.includes('1')) obj.isSecured = 1
        if (value.includes('2')) obj.isUnsecured = 1
        if (value.includes('3')) obj = { isSecured: 1, isUnsecured: 1 }
        if (Object.keys(obj).length) return obj
        return undefined
    },
    ingramId: (value: boolean) => {
        if (!value) return undefined
        return {
            ingramId: {
                $not: null
            }
        }
    },
    sheetIds: (data: any[]) => {
        if (!data || !data?.length) return undefined
        return {
            sheetIds: Array.isArray(data) ? data.map(x => `${x}`) : [data]
        }
    }
}
