import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Table, TableBody, TableRow, } from "@mui/material";
import { TableContainer, TableDataContainer, TBodyRow } from "components/Table/style";
import TableDataContext from "components/Table/context/context";
import { TRequestOptions, TTableState } from "components/Table/d";
import { useExpanded, useResizeColumns, useTable } from "react-table";
import API from "api";
import TDCell from "components/Table/TDCell";
import TableFooter from "components/Table/TableFooter";
import THead from "components/Table/THead";
import { useLoading } from "hooks/useLoading";
import { deepEqual } from 'fast-equals';
import { NOOP } from "Utils";
import TableEmpty from "components/Table/TableEmpty";
import { TableEmptyCell } from "components/Table/TableEmpty/style";
import { getAllColumns } from "components/Table/helpers";

const TableRows = memo(({ rows, prepareRow, tableBordered, isAllRowsExpanded }: any) => {

    const { getColumnWidth } = useContext(TableDataContext)
    let selected = 0;
    return rows.map((row: any, rInd: number) => {
        prepareRow(row)
        if (row.depth === 0) selected = (selected + 1) % 2
        return (
            <TBodyRow {...row.getRowProps()} depth={row.depth} selected={!!selected}>
                {row.cells.map((cell: any, index: number) => {
                    const expandProps = index === 0 ? {
                        expand: row.canExpand,
                        expandProps: row.canExpand ? row.getToggleRowExpandedProps() : {},
                        isAllRowsExpanded
                    } : {}
                    const width = (() => {
                        if (!getColumnWidth) return
                        return getColumnWidth(cell.column.id)
                    })()
                    return <TDCell key={`cell-${index}`} {...expandProps} width={width} tableBordered={tableBordered}
                        cell={cell} />
                })}
            </TBodyRow>
        )
    })
})

const Component = ({
    visibleColumns,
    tableData,
    totalRecords,
    tableBordered,
    isVisibilityHeader = true,
    className,
    isResizable
}: any) => {

    const { setColumnResizing } = useContext(TableDataContext)

    const {
        getTableBodyProps,
        getTableProps,
        headerGroups,
        prepareRow,
        toggleAllRowsExpanded,
        isAllRowsExpanded,
        rows,
        state,
    } = useTable(
        {
            columns: visibleColumns,
            data: tableData,
            pageCount: totalRecords,
            defaultColumn: {
                minWidth: 30,
                width: 65,
                maxWidth: 400
            },
            manualFilters: true,
            manualGlobalFilter: true,
            manualPagination: true
        } as any,
        useExpanded,
        useResizeColumns
    ) as any

    useEffect(() => {
        if (!tableData) return
        if (!isAllRowsExpanded) {
            toggleAllRowsExpanded(true)
        }
    }, [tableData, isAllRowsExpanded])

    const emptyColSpan = useMemo(() => (getAllColumns(visibleColumns).flat()).length + 1, [visibleColumns])

    useEffect(() => {
        if (!setColumnResizing || !state.columnResizing?.columnWidths) return
        setColumnResizing(state.columnResizing?.columnWidths)
    }, [state, setColumnResizing])


    return (
        <Table stickyHeader {...getTableProps()} className={className}>
            <THead headerGroups={headerGroups} isResizable={isResizable} isVisibilityHeader={isVisibilityHeader} />
            <TableBody {...getTableBodyProps()}>
                {
                    !tableData?.length ? (<TableRow>
                        <TableEmptyCell colSpan={emptyColSpan}>
                            <TableEmpty message={"No data available"} />
                        </TableEmptyCell>
                    </TableRow>)
                        : (<TableRows rows={rows} isAllRowsExpanded={isAllRowsExpanded} prepareRow={prepareRow}
                            tableBordered={tableBordered} />)
                }
            </TableBody>
        </Table>
    )
}

export const StaticTable = (props: any) => <Component {...props} />

const TableComponent = ({
    csvFileName,
    title,
    modelName,
    needRefetch,
    refetchDone = NOOP,
    tableBordered,
    usePagination,
    className,
    hideLoading,
    preventFetch,
    emitFetchSuccess,
    isResetPagination,
    rowsPerPageOptions,
    children,
    useHeader,
    FooterChildren,
    isResizable,
    actionDownloadDefinition,
    postFetchFn = null
}: any) => {
    const [tableState, setState] = useState<TTableState>({ // The rows and row count of the table (this is the main info)
        rows: [],
        count: 0
    })
    const { setLoading, resetLoading } = useLoading() // ???
    const { isNeedRefetch, requestOptions, setPage, visibleColumns } = useContext(TableDataContext) // useContext is a React Hook that lets yhou read and subscribe to context from your component

    const refForFetch = useRef({
        hideLoading,
        preventFetch
    })
    refForFetch.current = {  // If the table is visible, we want to show loading and not prevent fetch. Most of the time, these should both be false. 
        hideLoading,
        preventFetch
    }

    const refControllerCallAbort = useRef(null) // useRef is a React Hook that lets you reference a value that's not needed for rendering
    const refPrevTime = useRef(new Date().getTime())  // Current time
    /* GET DATA TO POPULATE THE TABLE */
    const fetchData = useCallback(async ({ forceShowLoading, ...data }: TRequestOptions & { // useCallback is a React Hook that lets you cache a function definition between re-renders
        forceShowLoading?: boolean
    }) => {
        const time = new Date().getTime();  // Current time
        refPrevTime.current = time  // Set refPrevTime to current time
        if (!modelName || refForFetch.current.preventFetch) return  // ???
        try {
            if (!(refForFetch.current.hideLoading && !forceShowLoading)) setLoading({  // ???
                isMini: true
            });
            (refControllerCallAbort.current as any)?.abort() // ???
            console.log("Data: ", data);
            let { result } = await API.getModelAllCount(modelName, data, true, refControllerCallAbort)  // Fetch all rows from modelName
            console.log("Result: ", result);
            if (postFetchFn) {
                result = await postFetchFn(result, refControllerCallAbort)  // Get info we want for the table that's not in modelName table
            }
            refControllerCallAbort.current = null // ???
            setState({  // Set the table rows and row count
                ...result
            })
            emitFetchSuccess && emitFetchSuccess(result) // ???
            refetchDone() // Get rid of the loading icon once the data has been fetched and set
        } catch (e) {
            console.log(e)  // Deal with errors
            return;
        } finally {
            resetLoading()  // ???
        }
    }, [setLoading, resetLoading, refetchDone, setState, modelName, refForFetch, emitFetchSuccess]) // Dependencies
    /* SET TABLE DATA */
    const [tableData, totalRecords] = useMemo(() => [ // useMemo is a React Hook that lets you cache the result of a calculation between re-renders
        [...tableState.rows].map((x: any, index: number) => ({  // Get all table data mapped to an index corresponding to the row number
            ...x,
            index: index + 1
        })),
        tableState.count // Total count of rows
    ], [tableState]) // Dependency

    const refReqOptions = useRef({}) // ??
    const refLastIsNeedRefetch = useRef(0) // ??

    const _requestOptions = useMemo(() => {  // ?? Why can't we just use requestOptions ?? Why do we need this code ??
        if (!deepEqual(requestOptions, refReqOptions.current)) refReqOptions.current = requestOptions // Set refReqOptions.current to requestOptions
        return refReqOptions.current
    }, [requestOptions, refReqOptions])

    useEffect(() => {  // ?? Does this reset to the first page when we refresh? This might be useful !!!
        if (!isResetPagination) return
        setPage(0)
    }, [isResetPagination, setPage])

    useEffect(() => {
        if (!isNeedRefetch && !needRefetch) return
        fetchData({  // Call fetchData to get the table data
            ..._requestOptions,
            forceShowLoading: (() => {
                if (refLastIsNeedRefetch.current === isNeedRefetch) return false
                refLastIsNeedRefetch.current = isNeedRefetch as number
                return true
            })()
        } as any).then()
    }, [fetchData, isNeedRefetch, _requestOptions, needRefetch])

    return (
        <TableDataContainer className={className}>
            {useHeader ? children : null}
            <TableContainer>
                <Component
                    tableBordered={tableBordered}
                    visibleColumns={visibleColumns}
                    tableData={tableData}
                    totalRecords={totalRecords}
                    isResizable={isResizable}
                />
            </TableContainer>
            {usePagination &&
                <TableFooter title={title} csvFileName={csvFileName} modelName={modelName} totalRecords={totalRecords}
                    rowsPerPageOptions={rowsPerPageOptions} FooterChildren={FooterChildren}
                    actionDownloadDefinition={actionDownloadDefinition} />}
        </TableDataContainer>
    )
}

export default TableComponent
