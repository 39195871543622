import React, {
  useContext,
  useCallback,
  useEffect
} from 'react'
import SearchInput from "components/Input/SearchInput";
import { TableSearchInputContainer } from "components/Table/Search/style";
import TableDataContext from "components/Table/context/context";

const TableSearch = ({ className, tooltipText }: any) => {

  const { setFilter, clearFilter } = useContext(TableDataContext)

  const clearSearch = useCallback(() => {
    clearFilter('globalSearch')
  }, [clearFilter])

  const handlerSearch = useCallback((value: string) => {
    console.log("About to set filter for ", value);
    setFilter({
      field: 'globalSearch',
      data: value
    })
  }, [setFilter])

  return (
    <TableSearchInputContainer className={className}>
      <SearchInput
        fullWidth
        tooltipText={tooltipText}
        handlerSearch={handlerSearch}
        clearSearch={clearSearch}
      />
    </TableSearchInputContainer>
  )
}

export default TableSearch
