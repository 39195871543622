import styled, { css } from "styled-components";
import { Flex } from "style";
import {
  TableCell,
  TableSortLabel,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import FilterListIcon from "@mui/icons-material/FilterList";
import MenuIcon from "@mui/icons-material/Menu";
import TuneIcon from "@mui/icons-material/Tune";
import ButtonIcon from "components/Button/ButtonIcon";

export const TableHeaderContainer = styled(Flex)`
  width: calc(100% + 4px);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  
`

export const ColumnVisibilityHolder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const THCellContainer = styled(TableCell) <{ index: number }>`
  position: relative;
  user-select: none;
  &.MuiTableCell-root {
    z-index: 10000;
    top: 0; 
    margin: 0;
    position: sticky;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 0;
    cursor: pointer;
    background-color: #1976d2;
    border-right: 1px solid white;
    color: #fff;
    font-size: 10px;
    ${props => (props.index & 0x1) && css`
      background-color: #1569bb;
    `}
  }
`
export const THCellLabelContainer = styled(Flex)`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 4px 2px;
  position: relative;
`

export const ThCellLabel = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  text-shadow: 0.5px 0.5px #000;
`

export const THCellLabelSortContainer = styled(Flex) <{ isFilterSort?: boolean }>`
  flex: 1;
  justify-content: center;
  align-items: center;
  ${props => props.isFilterSort && css`
    padding-right: 15px;
  `}
`
export const THCellResizer = styled(Flex) <{ isResizing?: boolean }>`
  width: 4px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(35%);
  z-index: 290000;
  background: #175781;
  border: 0.5px solid rgba(225, 225, 225, 0.7);

  ${props => props.isResizing && css`
    border: 0.5px solid rgba(225, 225, 225, 0.7);
  `}
`

export const THCellFilterContainer = styled(Flex)`
  flex: 2;
  height: 100%;
  width: 100%;

`
export const THCellFilterEmptyContainer = styled(Flex)`
  flex: 2;
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  align-items: center;
  background-color: white;
  color: rgba(34, 34, 34, 0.18);
  justify-content: center;
`

export const THCellFilter = styled(Flex)`
  flex: 2;
  height: 100%;
  width: 100%;
  background-color: white;
  padding: 0 4px;

  > div {
    height: 100%;
    width: 100%;
  }
`

export const TableHeaderTitle = styled(TableSortLabel)`
  &.MuiTypography-root {
    font-size: 20px;
    letter-spacing: 0.005em;
    font-weight: 400
  }`

export const TableHeaderOut = styled(Flex)`
  flex: 2;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const TableHeaderGlobalSearchVisibilityContainer = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
`

export const THCellLabelSort = styled(TableSortLabel)`
  &.MuiTableSortLabel-root {
    position: absolute;
    left: 2px;
    top: 0;
    bottom: 0;
  }

  & .MuiTableSortLabel-icon {
    color: #fff !important
  }
`

export const THCellButtonFilterContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 4px;
  top: 0;
  bottom: 0;
  z-index: 250000;
`

export const ThCellFilterButtonSortIcon = styled(FilterListIcon)`
  &&& {
    font-size: inherit;
  }
`

export const ThCellFilterButton = styled(ButtonIcon) <{ isActive?: boolean, _asc?: boolean }>`
  &&& {
    font-size: 12px;
    padding: 1px;
    color: ${blueGrey["300"]};

    ${props => props?.isActive && css`
      color: white;
      transform: scale(1.4);
      border: 0.5px solid rgba(255, 255, 255, 0.3);
    `}
  }

  ${ThCellFilterButtonSortIcon} {
    &&& {
      font-size: inherit;
    }

    ${props => props?._asc && css`
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    `}
  }
`
export const ThCellFilterButtonIcon = styled(MenuIcon)`
  &&& {
    font-size: inherit;
  }
`

export const ThCellFilterButtonFilterIcon = styled(TuneIcon)`
  &&& {
    font-size: inherit;
  }
`

