import styled, { css } from "styled-components";
import Colors from "def/Colors";
import { Flex } from "style";
import Edit from '@mui/icons-material/EditOutlined';
import { ChatBubbleOutline } from '@mui/icons-material'


export const AmazonLinkContainer = styled(Flex) <{ isCa?: boolean }>`
  justify-content: space-between;
  align-items: center;
  
  
  ${props => !props.isCa && css`
    justify-content: center;
  `}
`
export const AmazonLinkUS = styled.a<{ isInvalid?: boolean }>`
  color: ${Colors.PALETTE.BLUE._900};
  text-decoration: none;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
    transform: scale(1.01);
  }
  
  &:active {
    transform: scale(0.98);
  }
  
  
  ${props => props.isInvalid && css`
    color: ${Colors.PALETTE.GRAY._500};
  `}
  
`

export const AmazonLinkCA = styled.a<{ isInvalid?: boolean }>`
  color: ${Colors.PALETTE.BLUE._900};
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: relative;
  
  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.98);
  }
  
  ${props => props.isInvalid && css`
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: ${Colors.PALETTE.GRAY._100};
      opacity: 0.9;
    }
  `}  
`
// Add styles for the edit button
export const EditButton = styled(Edit)`
    cursor: pointer;
    font-size: 10px; 
    color: #2f4f4f;
    margin-left: 10px; 

    &:hover {
    color: #000000;
  }
`

export const NotesButton = styled(ChatBubbleOutline)`
    cursor: pointer;
    font-size: 10px; 
    color: #2f4f4f;
    margin-left: 10px; 
    margin-right: 10px;

    &:hover {
    color: #000000;
  }
`

export const EditForm = styled(Flex)`
    display:flex; 

    input {
      width: 100%; 
    }

    
`