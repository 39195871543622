import {
    random,
    noop,
    isString
} from "lodash";
import { format } from 'date-fns'
import CryptoJS from 'crypto-js'

export const NOOP = noop;
export const emptyObject = {} as any
export const emptyArray = [] as any[]

export const formatDateTime = (value: string) => {
    if (!value || value === '') {
        value = new Date().toString()
    }
    return new Date(value).toLocaleString(void (0), { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'America/Phoenix' } as any)
}

export const formatDate = (val: any, _format = 'yyyy-MM-dd HH:mm') => {
    if (!val) return ''
    const value = isString(val) ? new Date(val) : val
    return format(value, _format)
}


export const formatPrice = (value?: string | number, min = 2, max = 2) => {
    if (typeof value === 'undefined' || value === null) return ''
    if (typeof value === 'number' && isNaN(value)) return ''
    const valNumber = typeof value === 'number' ? value : Number(value)
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: min,
        maximumFractionDigits: max
    })
    return `$${formatter.format(valNumber)}`
}


export const formatQuantity = (value: string | number) => {
    if (typeof value === 'undefined' || value === null) return ''
    const valNumber = typeof value === 'number' ? value : Number(value)
    if (isNaN(valNumber)) return ''
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    })
    return formatter.format(valNumber)
}

export const formatCondition = (value: string | number) => {
    if (typeof value === 'undefined' || value === null) return 'N/A'  // Check for null values
    const valNumber = typeof value === 'number' ? value : Number(value)
    if (valNumber === 1) return 'New'
    else if (valNumber === 2) return 'Used'
    else return 'N/A'
}


export const guid = () => `${random(10000, 100000)}-${random(10000, 100000)}`


export const hash1Create = (data: string | object) => {
    const s = isString(data) ? data : JSON.stringify(data)
    return CryptoJS.SHA1(s).toString()
}
