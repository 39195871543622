import React, {
    useState,
    useRef,
    useCallback,
    useEffect,
    useMemo
} from 'react'
import InputText from "components/Input/index";
import { TInputTextSearchProps } from "components/Input/d";
import {
    Clear,
    Search,
    InfoOutlined
} from "@mui/icons-material";

const SearchInput = ({
    handlerSearch,
    timeOutTrigger = 400,
    searchIcon = Search,
    clearSearch,
    placeholder = "Search",
    tooltipText,
    defaultValue,
    isClearable = true,
    ...rest
}: TInputTextSearchProps) => {

    const ref = useRef()
    const [value, setValue] = useState(defaultValue as string || "")
    const timer = useRef(0)

    const triggerSearch = useCallback((value: string, time?: number) => {
        console.log("Triggering Search...")
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            handlerSearch(value)
        }, time || timeOutTrigger) as any
    }, [handlerSearch, timer, timeOutTrigger])

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist()
        setValue(e.target.value)
        triggerSearch(e.target.value)
    }, [setValue, triggerSearch])


    const setFocus = useCallback(() => {
        if (!ref || !ref.current) return
        (ref.current as any).focus()
    }, [ref])

    const handlerClear = useCallback(() => {
        if (clearSearch) clearSearch()
        else handlerSearch('')
        setValue('')
        setFocus()
    }, [setValue, clearSearch, setFocus])

    const searchInputProps = useMemo(() => ({
        iconRight: isClearable && value?.length ? {
            icon: Clear,
            handler: handlerClear,
            color: value ? 'error' : "disabled"
        } : {
            icon: searchIcon
        } as any,
        icon: tooltipText ? {
            icon: InfoOutlined,
            tooltip: {
                title: tooltipText
            }
        } : undefined
    }), [value, isClearable, tooltipText])


    useEffect(() => {
        if (value?.length) return
        if (!value?.length) handlerClear()
    }, [value, onChange])


    return (
        <InputText
            inputRef={ref}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            useHelperText={false}
            {...searchInputProps}
            {...rest}
        />
    )
}

export default SearchInput
