import React, {
    memo,
    useContext
} from 'react'
import {
    TableHead
} from "@mui/material";
import ThCell from "components/Table/TableHeader/ThCell";
import { THeadRow } from "components/Table/style";
import TableDataContext from "components/Table/context/context";

const stickyHeaderStyle = {  // Makes the table header fixed
    position: 'sticky', // Makes it sticky
    top: 0,             // Sticks to the top of the table container
    zIndex: 10,         // Makes sure the header stays on top of the body
    backgroundColor: '#fff',  // Ensure the background is solid, so it's not transparent
};

const THead = memo(({ headerGroups, isVisibilityHeader = true, isResizable }: any) => {
    const { getColumnWidth } = useContext(TableDataContext)

    return (
        <TableHead sx={stickyHeaderStyle}>
            {headerGroups.map((headerGroup: any) => (
                <THeadRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any, index: number) => {
                        const width = getColumnWidth?.(column.id)
                        return <ThCell isResizable={isResizable} key={index} width={width} column={column} index={index} isVisibilityHeader={isVisibilityHeader && !column?.placeholderOf && index === 0} />
                    }
                    )}
                </THeadRow>
            ))}
        </TableHead>
    )
})


export default THead
