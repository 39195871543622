import {
    VISIBILITY,
    AmazonWinningBuyBox
} from "def";

const OUR_OFFER_ID = '10000004536'

export const wbEligibleAmazon = (row: any) => {
    const timeMin = new Date()
    timeMin.setDate(timeMin.getDate() - 30)
    if (row?.lastTimeWinningBuyBox > timeMin) return 'Y'
    return 'N'
}


export const getEbayColorText = (row: any) => {
    if (!row) return undefined
    if (row.ebayEndDate) {
        const date = new Date(row.ebayEndDate)  // Our end date
        let dateT = date.getTime()
        const current = new Date().getTime()  // Current time
        if (current > dateT) {
            return {
                color: '#000000',
                text: 'Ended - This should\'t be here.'
            }
        }
        const oneDay = 1000 * 60 * 60 * 24 // The number of milliseconds in a day
        let _date = Math.floor(dateT / oneDay)  // Converts end date into "day number" (the number of days since the Unix epoch)
        let _current = Math.floor(current / oneDay) // Converts current day into "day number"
        if (_date === _current) {  // If the end date's day number is the same as the current day's, the listing is ending today.
            return {
                color: '#e46161',
                text: 'Ending Today'
            }
        }
        // Get one day ahead of the current time (i.e. tomorrow)
        let tomorrow = _current + 1
        if (_date === tomorrow) { // If it's ending tomorrow
            return {
                color: '#f1b963',
                text: 'Ending Tomorrow'
            }
        }
        let endOfWeek = _current + 7
        if (_date < endOfWeek) {
            const endDateString = row.ebayEndDate.split('-')
            return {
                color: '#f8f398',
                text: `Ending This Week: ${endDateString[1]}/${endDateString[2].substring(0, 2)}`
            }
        }
        return {
            color: 'transparent',
            text: 'Active'
        }
    }
    if ((Number(row.ebayQuantity) === 0 && row?.ebayCountDownChecked) || !row?.ebayActive) {
        return {
            color: '#000000',
            text: 'Sold - This should\'t be here.'
        }
    }
    return {
        color: 'transparent',
        text: 'Active'
    }
}

export const visibilityForAmazon = (row: any) => {
    if (!row.asin) return ''
    const timeMin = new Date()
    timeMin.setDate(timeMin.getDate() - 30)
    if (row.amazonActive) return VISIBILITY.LISTED
    if (row.amazonStoreLastActive > timeMin) return VISIBILITY.LISTABLE
    return VISIBILITY.NONE
}

export const getOptimizeScoreForAmazon = (row: any) => {
    if (!row.amazonActive) return 0
    switch (row?.winningBuyBox) {
        case AmazonWinningBuyBox.WINNING_BUY_BOX_OURS: {
            if (row.lowestPriceFlag) return 6
            return 5
        }
        case AmazonWinningBuyBox.WINNING_BUY_BOX_EXISTS: {
            if (row.lowestPriceFlag) return 4
            return 3
        }
        case AmazonWinningBuyBox.WINNING_BUY_BOX_NOT_EXISTS:
        case AmazonWinningBuyBox.WINNING_BUY_FIRST_OUT_POSITION: {
            if (row.lowestPriceFlag) return 2
            return 1
        }
        default:
            return 0
    }
}


export const walmartVisibility = (walmart: any) => {
    if (!walmart?.walmartLifeCycleStatus || !walmart?.walmartPublishStatus) return ''
    return `${walmart?.walmartLifeCycleStatus.substring(0, 2)}_${walmart?.walmartPublishStatus.substring(0, 2)}`
}

export const getWalmartOptimizeScore = (walmart: any) => {
    if (!walmart.walmartActive) return ''
    if (walmart.walmartBuyBoxSellerId === OUR_OFFER_ID) {
        return walmart.walmartLowestPriceFlag ? 6 : 5
    }

    if (walmart.walmartBuyBoxSellerId !== null) {
        return walmart.walmartLowestPriceFlag ? 4 : 3
    }
    return walmart.walmartLowestPriceFlag ? 2 : 1
}


