import RestrictedField from "pages/Legacy/Marketing/components/RestrictedField";


const _RestrictedFields = (restProps: { isUsed?: boolean }) => [
    // {
    //     Header: "RES TB",
    //     accessor: "isRestrictedLL",
    //     sellerID: 7
    // },
    {
        Header: "RES SM1",
        accessor: "isRestrictedSM1",
        sellerID: 11
    },
    {
        Header: "RES AT",
        accessor: "isRestrictedAT",
        sellerID: 12
    },
    {
        Header: "RES NR",
        accessor: "isRestrictedNR",
        sellerID: 13
    }
    // {
    //     Header: "RES NGN",
    //     accessor: "isRestrictedFF", //field for search?? double check
    //     sellerID: 6 //id in sellers table
    // }
].map(x => ({

    Header: x.Header,
    accessor: x.accessor,
    canFilter: false,
    canSort: false,
    style: {
        minWidth: 60,
        width: 60,
    },
    Cell: (props: any) => <RestrictedField {...props} sellerID={x.sellerID} {...restProps} />
}))

const RestrictedFields = _RestrictedFields({})
export const RestrictedFieldsUsed = _RestrictedFields({ isUsed: true })

export default RestrictedFields
